<template>
  <div style="height:100%">

    <div :style="topStyle">
      <div class="text-center" style="color:#837c7a">다음은 온라인에 공개적으로 노출되는 <b>기본 정보</b>입니다.</div>
      <div class="size-36 text-center"><b>내 서비스의 정보</b>를 입력해주세요.</div>
      <div class="flex-align justify-center margin-top-56" style="color:#837c7a">
        <div>내 서비스의 이름은</div>
        <input class="input margin-left-12 margin-right-12 font-primary weight-600" type="text"
               style="width:300px" placeholder="텍스트를 입력해주세요" :disabled="disabled"
              :value="service.service_name" @keyup="e => service.service_name = e.target.value"/>
        <div>이며</div>
      </div>

      <div class="flex-align justify-center margin-top-24" style="color:#837c7a">
        <div>서비스 아이디는</div>
        <div>
          <div class="position-relative margin-left-12 margin-right-12">
            <input class="input font-primary weight-600" type="text"
                   placeholder="영문,숫자로 기입해주세요"
                   style="width:300px" maxlength="20" :disabled="disabled"
                   v-model="service.key" @input="onKeyupServiceId"/>
              <div class="err" v-if="err">{{ err }}</div>
          </div>
        </div>
        <div>입니다.</div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "SuperBuilderStep2",

    props: {
      service: {
        type: Object
      }
    },
    data() {
      return {
        err: undefined,
        disabled: false
      }
    },
    mounted() {
      this.$nextTick(()=>{
        this.disabled = true;
        setTimeout(()=>{
          this.disabled = false;
        },300);
      })
    },
    computed: {
      topStyle() {
        return {
          position: 'absolute',
          top: '45%',
          left: '50%',
          transform: 'translate(-45%, -50%)'
        }
      },
    },
    methods: {
      onKeyupServiceId(e) {
        // eslint-disable-next-line no-useless-escape
        let regExp = /[\{\}\[\]\/?_.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi;
        // 특수문자 예외처리
        if (regExp.test(e.target.value)) {
          this.service.key = this.service.key.replace(regExp, '');
        } else if (e.target.value.length > 0 && /[a-zA-Z]/.test(e.target.value[0])) {
          this.service.key = e.target.value;
        } else {
          this.service.key = '';
        }
        this.service.key = this.service.key.toLowerCase();
      },
    }
  }
</script>
<style lang="stylus" scoped>
  .err
    position absolute
    left 0
    color red
    font-size 12px
</style>
